import React from "react"
import { Link } from "gatsby"

import Header from "../components/Header"
import SEO from "../components/seo"

import style from "../css/download.module.css"

const Download = () => (
  <div className={style.container}>
    <Header siteTitle={"download Queue "} />
    <SEO title="Download" />
    <div className={style.button}>
      <a
        href="https://apps.apple.com/dk/app/queue/id1484697546"
        className={style.link}
      >
        IOS
      </a>
    </div>
    <div className={style.button}>
      <a
        href="https://play.google.com/store/apps/details?id=com.ankersway.queue"
        className={style.link}
      >
        Android
      </a>
    </div>
    <Link
      to="/"
      style={{
        color: `#333`,
        textDecoration: `none`,
        flex: 1,
        textAlign: "center",
      }}
    >
      <p className={style.mainSiteLink}>want to know more?</p>
    </Link>
  </div>
)

export default Download
